import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Link from "components/Link";
import Wrapper from "components/Wrapper";
import { FieldLocale } from "utils/common/locale";
import { getPersistedLocale } from "utils/persistence/locale";
import SEO from "components/SEO";
import { useTranslation } from "hooks/useTranslation";
import { computePath } from "utils/common/navigation";
import { useLocale } from "hooks/useLocale";

interface Props {
  pageContext: {
    defaultLocale: string;
    allPathLocales: FieldLocale<string>[];
  };
}

/**
 * Custom 404 page
 */
const NotFoundPage: React.SFC<Props> = ({ pageContext }) => {
  const { defaultLocale, allPathLocales } = pageContext;

  // State variables
  const [locale, setLocale] = useState<string>();

  async function loadLocale() {
    const locale = await getPersistedLocale();
    setLocale(locale || defaultLocale);
  }

  // Effects
  useEffect(() => {
    loadLocale();
  }, []);

  return locale ? <NotFound /> : null;
};

const NotFound = () => {
  const { formatMessage } = useTranslation();
  const { locale, defaultLocale } = useLocale();

  return (
    <Wrapper size="tiny">
      <SEO title={formatMessage({ id: "SEO.404Title" })} />
      <Content>
        <Emoji>⚠️</Emoji>
        <Title>
          <FormattedMessage id="404.title" />
        </Title>
        <Subtitle>
          <FormattedMessage
            id="404.subtitle"
            values={{
              homePage: (
                <Link to={locale === defaultLocale ? `/` : `/${locale}/`}>
                  homepage
                </Link>
              )
            }}
          />
        </Subtitle>
      </Content>
    </Wrapper>
  );
};

const Content = styled.div`
  padding-top: 100px;
  padding-bottom: 20px;
`;

const Emoji = styled.div`
  text-align: center;
  font-size: 40px;
`;
const Title = styled.h1`
  text-align: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
`;
const Subtitle = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.8em;
  margin: 0;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.main};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default NotFoundPage;
